<template>
  <VNavigationDrawer
    v-model="drawer"
    class="bubs-mobile-nav-bar bubs-wax-paper"
    app
    right
    temporary
  >
    <BaseButton
      icon
      fab
      small
      color="bubs-charcoal"
      md-icon="close"
      aria-label="Close Menu"
      @click="$store.commit('nav/TOGGLE_DRAWER')"
    ></BaseButton>
    <VList>
      <VListTile
        to="/"
        exact
        exact-active-class="bubs-purple--text"
        @click.native.stop="currPageAction"
      >
        <VListTileContent>
          <VListTileTitle class="r-headline text-uppercase font-weight-bold"
            >Home</VListTileTitle
          >
        </VListTileContent>
      </VListTile>
      <VListGroup v-model="showMenusDropdown" no-action>
        <template #activator>
          <VListTile>
            <VListTileContent>
              <VListTileTitle
                class="r-headline text-uppercase font-weight-bold bubs-charcoal--text"
                >Menus</VListTileTitle
              >
            </VListTileContent>
          </VListTile>
        </template>

        <WithLocationSummaries v-slot="{ locations }">
          <div class="nav-drawer-locations">
            <VListTile
              v-for="loc in locations"
              :key="loc.title"
              :to="`/locations/${loc.slug}`"
              exact
              exact-active-class="bubs-purple--text"
              @click.native.stop="currPageAction"
            >
              <VListTileContent>
                <VListTileTitle
                  class="r-headline text-uppercase font-weight-bold"
                  >{{ loc.title }}</VListTileTitle
                >
              </VListTileContent>
            </VListTile>
          </div>
        </WithLocationSummaries>
      </VListGroup>
      <VListTile
        v-for="route of routes"
        :key="route.title"
        :to="route.to"
        :href="route.href"
        exact
        exact-active-class="bubs-purple--text"
        @click.native.stop="currPageAction"
      >
        <VListTileContent>
          <VListTileTitle class="r-headline text-uppercase font-weight-bold">{{
            route.title
          }}</VListTileTitle>
        </VListTileContent>
      </VListTile>

      <BaseButton
        class="ml-0 mt-3"
        aria-label="Order Online"
        color="bubs-red"
        large
        @click="isOrderDialogOpen = true"
        >Order Online</BaseButton
      >
    </VList>
  </VNavigationDrawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import WithLocationSummaries from '@dataProviders/WithLocationSummaries'

import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'MobileNavBar',
  components: { WithLocationSummaries },
  data() {
    return {
      showMenusDropdown: false,
      routes: [
        {
          title: 'About & Press',
          to: { path: '/about-and-press' },
          exact: true,
          position: 'center',
        },
        {
          title: 'Merchandise',
          href: 'https://bubsnola.store/',
          exact: true,
          position: 'center',
        },
        {
          title: 'Catering',
          to: { path: '/catering' },
          exact: true,
          position: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('locations', ['getLocationBySlug']),
    ...mapFields('nav', ['isOrderDialogOpen']),
    drawer: {
      get() {
        return this.$store.state.nav.isDrawerOpen
      },
      set(val) {
        this.$store.commit('nav/SET_DRAWER', val)
      },
    }, // drawer
    theLocation() {
      return this.getLocationBySlug('bubs')
    }, // theLocation
  },
  beforeMount() {
    // Set the drawer to closed on load
    this.$store.commit('nav/SET_DRAWER', false)
  },
  methods: {
    formatPhone,
    formattedPhone(location) {
      if (!location) {
        return false
      }
      return formatPhone(getContactDetail(location, 'phone_number'))
    }, // formattedPhone
    currPageAction() {
      this.$store.commit('nav/SET_DRAWER', false)
    }, // currPageAction
  },
}
</script>

<style lang="scss">
@import '@design';

.bubs-mobile-nav-bar {
  a,
  a:visited {
    color: $color-bubs-charcoal;
  }
}

.nav-drawer-locations {
  a.v-list__tile {
    padding-left: 40px;
    font-weight: bold;
  }
}
</style>
